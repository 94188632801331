import { Button, Spin, message } from "antd";
import { ErrorElement } from "common/components/StyledElements";
import APIConstants from "common/constants/APIConstants";
import COLORS from "common/constants/Colors";
import LANGUAGES from "common/constants/language";
import { phoneNumberRegex } from "common/constants/regex";
import { useAuthContext } from "common/contexts/AuthContext";
import LoginIcon from "common/icons/LoginPageLoginIcon.svg";
import LoginPagePharmacyImage from "common/icons/LoginPagePharmacyImage.png";
import LoginUserNameInput from "common/icons/LoginUserNameInput.svg";
import LoginUserPasswordInput from "common/icons/LoginUserPasswordInput.svg";
import LoginUserVIewPassword from "common/icons/LoginViewPasswordIcon.svg";
import RadioFalse from "common/icons/RadioFalse.svg";
import RadioTrue from "common/icons/RadioTrue.svg";
import ValuemediLogo from "common/icons/ValueMediLogo.png";
import NewEnneaLogo from "common/icons/NewEnneaLogo";
import NewLogo from "common/icons/VM_Logo";
import { validateNumberInput } from "common/utils";
import { api } from "common/utils/APIMethods";
import { MESSAGE_TYPE, showMessage } from "common/utils/message";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const SignInImageContainer = styled.img`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 50vw;
  float: left;
  height: 100vh;
  object-fit: cover;
`;

const InputElement = styled.input`
  border: 0px;
  outline: none;
  width: 20vw;
  color: ${COLORS.dark_shaded_grey};
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  ::placeholder {
    color: ${(props) =>
      props?.error ? COLORS.salmon_pink : COLORS.dusty_grey};
  }
`;

const TermsElement = styled.u`
  height: 40px;
  cursor: pointer;
  margin-top: 20px;
  color: ${COLORS.primary_green};
`;
const SupportUs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Contact = styled.div`
  color: ${COLORS.primary_green};
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`;
const LoginBodyContainer = styled.div`
  background: ${COLORS.login_body_gradient};
  width: 50vw;
  float: right;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const WelcomeToContainer = styled.div`
  color: ${COLORS.dark_teal};
  font-family: "Poppins";
  font-size: calc(3.91363rem * 0.7352);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: calc(-0.15656rem * 0.7352);
  margin-top: calc(10px * 0.7352);
  ${(props) =>
    props.isPhoneNumberLogin &&
    css`
      margin-top: calc(70px * 0.7352);
    `}
`;

const ValuemediLogoContainer = styled.div`
  width: calc(30.75rem * 0.7352);
  height: calc(7.6875rem * 0.7352);
  flex-shrink: 0;
  margin-bottom: calc(3.94rem * 0.7352);
  margin-top: 5px;
`;

const LoginDetailsInputContainer = styled.div`
  width: calc(30.75rem * 0.7352);
  height: calc(4.875rem * 0.7352);
  margin-bottom: 1.5rem;
  padding: 1.31rem;
  display: flex;
  column-gap: 1.31rem;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.75rem;
  background: ${COLORS.white};
  box-shadow: 0px 3px 18px -6px rgba(1, 191, 185, 0.4);
`;

const BottomTextContainer = styled.div`
  color: ${COLORS.dark_teal};
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  cursor: pointer;
`;

const RegisterAndForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(30.75rem * 0.7352);
`;

const LoginDetailsIconsContainer = styled.img`
  height: 1.75rem;
  width: 1.75rem;
  ${(props) =>
    props.password &&
    css`
      margin-left: auto;
      cursor: pointer;
    `}
`;

const SelectLanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.94rem;
  width: calc(27.75rem * 0.7352);
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  ${(props) =>
    props.isPhoneNumberLogin &&
    css`
      margin-top: 3rem;
    `}
`;

const UserLoginButton = styled(Button)`
  && {
    width: calc(30.75rem * 0.7352);
    height: calc(4.875rem * 0.7352);
    flex-shrink: 0;
    border-radius: 0.375rem;
    border: 1.5px solid rgba(0, 148, 143, 0.3);
    background: ${COLORS.login_button_gradient};
    box-shadow: 0px 6px 9px -4.5px rgba(0, 191, 185, 0.6);
    color: ${COLORS.white};
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
`;

const RadioButtonsConainer = styled.img`
  width: 1.75rem;
  height: 1.75rem;
  flex-shrink: 0;
`;

const RadioItemContainer = styled.div`
  display: flex;
  column-gap: 3px;
  width: 7.2rem;
  color: ${COLORS.dark_teal};
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  align-items: center;
  cursor: pointer;
`;

const RadioLanguageOptionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-left: 25px;
`;

const LoginOptionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: calc(30.75rem * 0.7352);
`;

const LoginOptionButton = styled(BottomTextContainer)`
  margin-bottom: 0.5rem;
  align-self: flex-end;
`;

const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    isAutoOtpGeneration = false,
    phoneNumber = "",
    username = ""
  } = state ?? {};
  const { dispatch, authConfig } = useAuthContext();
  const { loginURL, loginWithTokenURL, otpGenerate } = APIConstants;
  const [searchParams] = useSearchParams();
  const apiToken = searchParams.get("apiToken");
  const { i18n, t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showMobileNumberLogin, setShowMobileNumberLogin] = useState(false);
  const autoOtpGeneratedRef = useRef(false);

  const { isLoading: tokenLoginIsLoading } = useQuery(
    "loginWithToken",
    () =>
      api({
        url: loginWithTokenURL,
        method: "POST",
        body: {
          token: apiToken
        },
        checkAuth: false
      }),
    {
      enabled: !!apiToken,
      onSuccess: (response) => {
        dispatch({ type: "onLogin", payload: response });
        navigate("/dashboard", { replace: true });
      },
      onError: (response) => {
        showMessage(
          response?.message ?? "check_username_password",
          MESSAGE_TYPE.errorTranslate
        );
      }
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues: {
      phoneNumber,
      username,
      password: ""
    }
  });

  useEffect(() => {
    if (authConfig.sessionId && !apiToken) {
      navigate("/dashboard", { replace: true });
    }
  }, [apiToken, authConfig.sessionId, navigate]);

  const { isLoading, mutate } = useMutation(
    (formData) =>
      api({
        url: loginURL,
        method: "POST",
        body: formData,
        checkAuth: false
      }),
    {
      onSuccess: (response) => {
        dispatch({ type: "onLogin", payload: response });
        navigate("/dashboard", { replace: true });
      },
      onError: (response) => {
        showMessage(
          response?.message ?? "check_username_password",
          MESSAGE_TYPE.errorTranslate
        );
      }
    }
  );

  const onSubmit = (formData) => {
    mutate(formData);
  };

  const { isLoading: sendingOTP, mutate: otpGenerationMutation } = useMutation(
    (formData) =>
      api({
        url: `${otpGenerate}/${parseInt(formData.phoneNumber, 10)}`
      }),
    {
      onSuccess: (response) => {
        showMessage("otp_send_successful", MESSAGE_TYPE.successTranslate);
        navigate("/reset", {
          state: {
            phoneNumber: getValues("phoneNumber"),
            sessionId: response.sessionId,
            mode: 1,
            isPhoneNumberLogin: true
          }
        });
      },
      onError: (err) => {
        message.error(err.message);
      }
    }
  );

  const onPhoneNumberLogin = (formData) => {
    otpGenerationMutation(formData);
  };

  const onChangeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(showMobileNumberLogin ? onPhoneNumberLogin : onSubmit)();
    }
  };

  const registerOptions = {
    required: t("enter_phone"),
    pattern: {
      value: phoneNumberRegex,
      message: t("enter_valid_phone")
    }
  };

  useEffect(() => {
    if (isAutoOtpGeneration && !autoOtpGeneratedRef.current) {
      setShowMobileNumberLogin(true);
      otpGenerationMutation({ phoneNumber: getValues("phoneNumber") });
      autoOtpGeneratedRef.current = true;
    }
  }, []);

  if (tokenLoginIsLoading) {
    return <Spin />;
  }

  return (
    <MainContainer>
      <SignInImageContainer src={LoginPagePharmacyImage} about="LOGIN_IMAGE" />
      <LoginBodyContainer>
        <WelcomeToContainer isPhoneNumberLogin={showMobileNumberLogin}>
          <Trans i18nKey="welcome_to" />
        </WelcomeToContainer>
        {/* <ValuemediLogoContainer src={ValuemediLogo} /> */}
        <ValuemediLogoContainer><NewEnneaLogo/></ValuemediLogoContainer>
        {showMobileNumberLogin ? (
          <>
            <LoginDetailsInputContainer>
              <div>+91</div>
              <InputElement
                onKeyDown={validateNumberInput}
                {...register("phoneNumber", registerOptions)}
                error={errors?.phoneNumber}
                placeholder={t("enter_your_phone_number")}
                onKeyPress={handleEnterKeyPress}
                onBlur={(e) => setValue("phoneNumber", e.target.value.trim())}
              />
            </LoginDetailsInputContainer>
            {errors.phoneNumber && (
              <ErrorElement>{errors.phoneNumber.message}</ErrorElement>
            )}
          </>
        ) : (
          <>
            <LoginDetailsInputContainer>
              <LoginDetailsIconsContainer
                src={LoginUserNameInput}
                alt="USER_ICON"
              />
              <InputElement
                {...register("username", {
                  required: t("enter_your_username")
                })}
                placeholder={t("enter_your_username")}
                error={errors?.username}
                onKeyPress={handleEnterKeyPress}
                onBlur={(e) => setValue("username", e.target.value.trim())}
              />
            </LoginDetailsInputContainer>
            <LoginDetailsInputContainer>
              <LoginDetailsIconsContainer
                src={LoginUserPasswordInput}
                alt="USER_PASSWORD_ICON"
              />
              <InputElement
                {...register("password", {
                  required: t("enter_your_password")
                })}
                type={showPassword ? "text" : "password"}
                error={errors?.password}
                placeholder={t("enter_your_password")}
                onKeyPress={handleEnterKeyPress}
              />
              <LoginDetailsIconsContainer
                password
                src={LoginUserVIewPassword}
                alt="USER_PASSWORD_ICON"
                onClick={onChangeShowPassword}
              />
            </LoginDetailsInputContainer>
          </>
        )}
        <LoginOptionButtonContainer>
          <LoginOptionButton
            onClick={() => {
              setShowMobileNumberLogin(!showMobileNumberLogin);
            }}
          >
            <Trans
              i18nKey={
                showMobileNumberLogin
                  ? "login_with_user_name"
                  : "login_with_phone_number"
              }
            />
          </LoginOptionButton>
        </LoginOptionButtonContainer>
        <UserLoginButton
          loading={isLoading || sendingOTP}
          onClick={handleSubmit(
            showMobileNumberLogin ? onPhoneNumberLogin : onSubmit
          )}
        >
          <Trans i18nKey="login_to_valuemedi" />
          <LoginDetailsIconsContainer src={LoginIcon} alt="LOGIN" />
        </UserLoginButton>
        <RegisterAndForgotPasswordContainer>
          <BottomTextContainer onClick={() => navigate("/signup")}>
            <Trans i18nKey="create_new_account" />
          </BottomTextContainer>
          <BottomTextContainer onClick={() => navigate("/reset")}>
            <Trans i18nKey="forgot_password" />?
          </BottomTextContainer>
        </RegisterAndForgotPasswordContainer>
        <SelectLanguageContainer isPhoneNumberLogin={showMobileNumberLogin}>
          <BottomTextContainer>
            <Trans i18nKey="select_a_language" />
          </BottomTextContainer>
          <RadioLanguageOptionsContainer>
            {LANGUAGES?.map((lang) => (
              <RadioItemContainer
                key={lang.key}
                onClick={() =>
                  i18n.changeLanguage(lang.key).then((tn) => {
                    tn(lang.key);
                  })
                }
              >
                <RadioButtonsConainer
                  src={i18n.language === lang.key ? RadioTrue : RadioFalse}
                  alt="RADIO"
                />
                {lang.title}
              </RadioItemContainer>
            ))}
          </RadioLanguageOptionsContainer>
        </SelectLanguageContainer>

        <TermsElement onClick={() => navigate("/terms")}>
          Terms & Conditions
        </TermsElement>
        <SupportUs>
          <BottomTextContainer>
            <Trans i18nKey="support" />:
          </BottomTextContainer>
          <Contact>+91 40 3517 2357</Contact>
        </SupportUs>
      </LoginBodyContainer>
    </MainContainer>
  );
};

export default Login;
